import Flickity from 'flickity';

/**
 * Represents a Google Maps utility class.
 */
export class Google {
    /**
     * Constructs a new Google instance.
     */
    constructor() {
        // Initialize autocomplete and map on document ready
        $(document).ready(this.initAutocomplete.bind(this));
        $(document).ready(this.initMap.bind(this));
        $(document).ready(this.initSingleMap.bind(this));
        $(document).ready(this.closePropertyView.bind(this));
    }

    initAutocomplete() {
        $('.search-destination').each(function() {
            var destination = $(this),
                form = destination.closest('form'),
                latitudeInput = form.find('.search-latitude'),
                longitudeInput = form.find('.search-longitude');
    
            // Autocomplete options
            var options = {
                types: [], // Restrict to regions
                componentRestrictions: { country: 'UK' } // Restrict to United Kingdom
            };
    
            // Create autocomplete instance for each input
            var autocomplete = new window.google.maps.places.Autocomplete(destination[0], options);
    
            // Listen for place changes
            autocomplete.addListener('place_changed', function() {
                var place = autocomplete.getPlace();
                if (!place.geometry) {
                    console.log("Place details not found for the selected location.");
                    clearCoordinates();
                    disableSubmit();
                    return;
                }
                var lat = place.geometry.location.lat();
                var lng = place.geometry.location.lng();
    
                // Set latitude and longitude values
                latitudeInput.val(lat);
                longitudeInput.val(lng);
    
                // Enable form submission when a valid place is selected
                enableSubmit();
            });
    
            // Listen for manual input changes to clear coordinates
            destination.on('input', function() {
                clearCoordinates();
                disableSubmit();
            });
    
            // Function to clear coordinates
            function clearCoordinates() {
                latitudeInput.val('');
                longitudeInput.val('');
            }
    
            // Function to disable form submission if lat and long are empty
            function disableSubmit() {
                if (latitudeInput.val() === '' && longitudeInput.val() === '') {
                    form.find('button[type="submit"]').prop('disabled', true);
                }
            }
    
            // Function to enable form submission
            function enableSubmit() {
                form.find('button[type="submit"]').prop('disabled', false);
            }
    
            // Disable form submission initially
            disableSubmit();
        });
    }

    /**
     * Initializes the custom map with given latitude and longitude.
     * @param {number} lat - The latitude of the map's center.
     * @param {number} lng - The longitude of the map's center.
     */
    initSingleMap() {
        const mapElement = document.getElementById('single-map');
        if (!mapElement) {
            return false;
        }

        let lat = parseFloat(mapElement.getAttribute('data-lat'));
        let lng = parseFloat(mapElement.getAttribute('data-lng'));

        const map = new google.maps.Map(mapElement, {
            zoom: 15,
            center: { lat: lat, lng: lng }
        });

        new google.maps.Marker({
            position: { lat: lat, lng: lng },
            map: map,
            title: 'Property Location'
        });
    }

    /**
     * Initializes the map with markers.
     */
    initMap() {
        // Check if the map element exists
        const mapElement = document.getElementById('map');
        if (!mapElement) {
            return false;
        }

        let _this = this; // Store reference to 'this' for use in inner functions

        // Default coordinates and zoom for the whole United Kingdom
        const defaultCenter = { lat: 54.0, lng: -2.0 }; // Central UK
        const defaultZoom = 5; // Zoom level to show the whole UK

        // Define coordinates for different regions
        const regionCoordinates = {
            'cottages-in-east-midlands': { lat: 52.5, lng: -1.0 },
            'cottages-in-eastern-england': { lat: 52.0, lng: 0.5 },
            'cottages-in-north-east-england': { lat: 54.5, lng: -1.5 },
            'cottages-in-north-west-england': { lat: 53.5, lng: -2.5 },
            'cottages-in-northern-ireland': { lat: 54.5, lng: -5.9 },
            'cottages-in-scotland': { lat: 56.0, lng: -4.0 },
            'cottages-in-south-east-england': { lat: 51.5, lng: 0.1 },
            'cottages-in-south-west-england': { lat: 50.5, lng: -3.0 },
            'cottages-in-wales': { lat: 52.5, lng: -3.5 },
            'cottages-in-west-midlands': { lat: 52.4, lng: -1.9 },
            'cottages-in-london': { lat: 51.5074, lng: -0.1278 },
            'cottages-in-yorkshire-and-the-humber': { lat: 53.5, lng: -1.0 },
            
            'whitby': { lat: 54.485, lng: -0.611 },
            'salcombe': { lat: 50.243, lng: -3.769 },
            'dartmouth': { lat: 50.353, lng: -3.573 },
            'weymouth': { lat: 50.625, lng: -2.459 },
            'newquay': { lat: 50.416, lng: -5.075 },
            'keswick': { lat: 54.605, lng: -3.133 },
            'bowness-on-windermere': { lat: 54.355, lng: -2.915 },
            'scarborough': { lat: 54.280, lng: -0.420 },
            'wells-next-the-sea': { lat: 52.957, lng: 0.850 },
            'filey': { lat: 54.195, lng: -0.290 },
            'llandudno': { lat: 53.315, lng: -3.825 },
            'benllech': { lat: 53.315, lng: -4.215 },
            'rhosneigr': { lat: 53.230, lng: -4.505 },
            'conwy': { lat: 53.270, lng: -3.820 },
            'falmouth': { lat: 50.157, lng: -5.070 },
            'ambleside': { lat: 54.420, lng: -2.955 },
            'looe': { lat: 50.349, lng: -4.445 },
            'st-agnes': { lat: 50.300, lng: -5.180 },
            'lanreath': { lat: 50.385, lng: -4.525 },
            'pwllheli': { lat: 52.880, lng: -4.405 },
            'torquay': { lat: 50.460, lng: -3.525 },
            'haworth': { lat: 53.820, lng: -1.945 },
            'bridlington': { lat: 54.090, lng: -0.180 },
            'crantock': { lat: 50.400, lng: -5.095 },
            'marazion': { lat: 50.122, lng: -5.455 },
            'beaumaris': { lat: 53.260, lng: -4.085 }
        };

        // Extract latitude and longitude from URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const latitude = parseFloat(urlParams.get('latitude'));
        const longitude = parseFloat(urlParams.get('longitude'));

        // Check if latitude and longitude are valid numbers
        const hasLatLng = !isNaN(latitude) && !isNaN(longitude);

        // Check if the URL contains '/regions/' or '/towns/'
        const hasRegions = window.location.pathname.includes('/regions/') || window.location.pathname.includes('/towns/');

        // Determine center and zoom
        let center = defaultCenter;
        let zoom = defaultZoom;

        if (hasLatLng) {
            // Use latitude and longitude from URL parameters
            center = { lat: latitude, lng: longitude };
            zoom = 10;
        } else if (hasRegions) {
            // Extract the region slug from the URL
            const pathSegments = window.location.pathname.split('/');
            const region = pathSegments[2]; // Assuming /regions/region/page/

            // Check if we have coordinates for the region
            const regionCoords = regionCoordinates[region];
            if (regionCoords) {
                center = regionCoords;
                zoom = 6;
            }
        }

        // Create a new map instance
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: zoom,
            center: center
        });

        // Add markers to the map
        locations.forEach(function(location) {
            var marker = new google.maps.Marker({
                position: { lat: location.lat, lng: location.lng },
                map: map,
                title: location.title,
                label: {
                    text: `£${location.price}`,
                    color: "white",
                    fontSize: "10px",
                    fontWeight: "bold"
                },
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 20,
                    fillColor: "#272727",
                    fillOpacity: 1,
                    strokeWeight: 0
                }
            });

            // Load property details on marker click
            _this.loadProperty(marker, location);
        });
    }


    /**
     * Handles the click event on the .close-map element and unsets specific classes.
     */
    closePropertyView() {
        let property = $('#property-information');
        let close = $('.close-map');
        let body = $('body');

        close.on('click', function() {
            body.removeClass('overlay');
            property.removeClass('show');
        });
    }

    /**
     * Loads property information and initializes a Flickity carousel for the given marker and location.
     *
     * @param {object} marker - The map marker object.
     * @param {object} location - The location object containing property ID and other details.
     */
    loadProperty(marker, location) {
        let body = $('body');
        let property = $('#property-information');
        let section = $('section.cottage-archive');
        let carousel = property[0].querySelector('.carousel');
        let next = $(property).find('.next');
        let prev = $(property).find('.prev');
        let dots = $(property).find('.dots');
    
        // Add click event listener to the marker
        marker.addListener('click', function() {
            // Make AJAX request to fetch property information
            body.addClass('overlay');

            $.ajax({
                url: `/wp-json/wp/v2/properties/${location.id}`,
                method: 'GET',
                success: function(data) {
                    let images = data.acf.sykes_property_images_resized.split("\n"); // Split the image URLs
    
                    // Destroy existing Flickity instance if it exists
                    if (carousel.flickityInstance) {
                        carousel.flickityInstance.destroy();
                        carousel.flickityInstance = null;
                    }
    
                    // Clear existing carousel content
                    carousel.innerHTML = '';
    
                    // Loop through each image URL and add it to the carousel
                    images.forEach(function(imageUrl) {
                        if (imageUrl) {
                            let carouselCell = document.createElement('div');
                            carouselCell.classList.add('carousel-cell');
                            let figure = document.createElement('figure');
                            let img = document.createElement('img');
                            img.src = imageUrl;
                            img.alt = 'Property Image';
                            figure.appendChild(img);
                            carouselCell.appendChild(figure);
                            carousel.appendChild(carouselCell);
                        }
                    });
    
                    // Initialize Flickity carousel
                    carousel.flickityInstance = new Flickity(carousel, {
                        cellAlign: 'left',
                        pageDots: false,
                        prevNextButtons: false,
                        // adaptiveHeight: true,
                    });
    
                    // Detach any existing event handlers before adding new ones
                    next.off('click').on('click', function() {
                        carousel.flickityInstance.next();
                    });
    
                    prev.off('click').on('click', function() {
                        carousel.flickityInstance.previous();
                    });
    
                    window.dispatchEvent(new Event('resize'))
                    property.addClass('show');
    
                    // Update property information using a loop
                    let propertyInfo = [
                        { selector: 'h2', text: data.acf.sykes_property_name },
                        { selector: '.region', text: data.acf.sykes_property_region },
                        { selector: '.rating', text: data.acf.sykes_property_rating },
                        { selector: '.description', text: data.acf.sykes_short_description },
                        { selector: '.bedrooms', text: data.acf.sykes_property_bedrooms },
                        { selector: '.bathrooms', text: data.acf.sykes_property_bathrooms },
                        { selector: '.price', text: '£' + data.acf.sykes_property_lowest_price },
                        { selector: '.link', attr: 'href', value: data.link }
                    ];
    
                    propertyInfo.forEach(function(info) {
                        if (info.attr) {
                            $(info.selector, property).attr(info.attr, info.value);
                        } else {
                            $(info.selector, property).text(info.text);
                        }
                    });
                },
                error: function(xhr, status, error) {
                    console.error('Error fetching property information:', error);
                }
            });
        });
    }
}

// Initialize the Google class
new Google();
