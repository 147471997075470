import { gsap } from 'gsap';
import Flickity from 'flickity';

/**
 * Class representing components for managing different UI components.
 */
export class Components {
    
    /**
     * Constructs a Components object.
     */
    constructor() {
        this.carousel();
        this.faq();
        this.accordions();
        this.carouselAuto();
        this.initializeCalendar();
        this.filter();
        this.initializeFilters();
        this.map();
        this.menu();
        this.stickySearch();
        this.addHeaderClass();
    }

    addHeaderClass() {
        if ($('section.hero').hasClass('style-3')) {
            $('header.primary').addClass('style-3');
        }
    }

    stickySearch() {
        var hero = $('section.hero');
        var search = $('aside.search');

        if (hero.length && search.length) {
            var heroBottom = hero.offset().top + hero.outerHeight();

            $(window).on('scroll', function () {
                if ($(window).scrollTop() > heroBottom) {
                    search.addClass('show');
                } else {
                    search.removeClass('show');
                }
            });
        }
    }

    menu() {
        let body = $('body');
        
        // Function to toggle menu and body class
        function toggleMenu() {
            $('nav.menu').toggleClass('open');
            body.toggleClass('menu');
            body.toggleClass('fixed');
            body.removeClass('scrolling');

            // Update label text
            if ($('nav.menu').hasClass('open')) {
                $('.burger .label-menu').text('Close');
            } else {
                $('.burger .label-menu').text('Menu');
            }
        }
        
        // Click event for burger button in nav.menu
        $('.burger').on('click', function () {
            $(this).toggleClass('open');
            toggleMenu();
        });
    }

    map() {
        let section = $('section.map'),
            svg = $('svg', section),
            ul = $('ul', section);

        // Loop through each path in the SVG
        $('path', svg).each(function() {
            let path = $(this),
                id = path.data('id'),
                slug = path.data('slug'),
                count = path.data('count');

            // Remove hyphens and capitalize the ID
            let capitalizedId = id.replace(/-/g, ' ').replace(/\b\w/g, function(char) {
                return char.toUpperCase();
            });

            // Create the href link
            let href = `/regions/${slug}`;

            // Create the <a> element
            let link = $(`<a href="${href}" data-path-id="${id}">${capitalizedId} (${count})</a>`);

            // Append the <a> element to the <li> inside the <ul>
            ul.append($('<li>').append(link));

            // Add hover handlers to path
            path.hover(
                function() {
                    // Mouse enter: change color of the path
                    path.css('fill', '#438269'); // Change to desired hover color

                    // Add hover class to corresponding anchor tag
                    link.addClass('hover');
                },
                function() {
                    // Mouse leave: revert color of the path
                    path.css('fill', ''); // Revert to original color

                    // Remove hover class from corresponding anchor tag
                    link.removeClass('hover');
                }
            );

            // Add hover handlers to anchor tag
            link.hover(
                function() {
                    // Mouse enter: change color of the path
                    path.css('fill', '#438269'); // Change to desired hover color
                },
                function() {
                    // Mouse leave: revert color of the path
                    path.css('fill', ''); // Revert to original color
                }
            );

            // Add click handler to path
            path.click(function(event) {
                // Trigger click on the corresponding anchor tag
                link.get(0).click(); // Use get(0) to access the raw DOM element and trigger click
            });

            // Add click handler to anchor tag
            link.click(function(event) {
                // Prevent default action (following the link)
                event.preventDefault();

                // Your custom click handling code here
                console.log('Anchor tag clicked:', capitalizedId);

                // Optionally, redirect to the href
                window.location.href = $(this).attr('href');
            });
        });
    }

    /**
     * Initializes the filter checkboxes based on URL parameters.
     */
    initializeFilters() {
        const urlParams = new URLSearchParams(window.location.search);
        const filters = urlParams.getAll('filter');
        
        if (filters.length > 0) {
            filters.forEach(filter => {
                const filterValues = filter.split(',');
                filterValues.forEach(value => {
                    $(`#filterForm input[type="checkbox"][value="${value}"]`)
                        .prop('checked', true)
                        .closest('.carousel-cell')
                        .addClass('selected');
                });
            });
        }
    }

    /**
     * Sets up event listeners for filter interaction.
     */
    filter() {
        $(document).on('click', '.carousel-cell', function() {
            const $checkbox = $(this).find('input[type="checkbox"]');
            $checkbox.prop('checked', !$checkbox.prop('checked'));
            $(this).toggleClass('selected');
        });

        $('#applyFilterBtn').on('click', () => {
            const selectedTerms = [];
            $('#filterForm').find('input[type="checkbox"]:checked').each(function() {
                selectedTerms.push($(this).val());
            });

            this.updateFilters(selectedTerms);
        });

        $('#resetFilterBtn').on('click', () => {
            $('#filterForm').find('input[type="checkbox"]').prop('checked', false).closest('.carousel-cell').removeClass('selected');
            this.updateFilters([]);
        });
    }

    /**
     * Updates the URL with the current filter terms.
     * @param {array} selectedTerms Array of selected filter terms.
     */
    updateFilters(selectedTerms) {
        const params = new URLSearchParams(window.location.search);
        params.delete('filter');
        if (selectedTerms.length > 0) {
            params.append('filter', selectedTerms.join(','));
        }
        const newUrl = `${window.location.pathname}?${params.toString()}${window.location.hash}`;
        window.location.href = newUrl; // Redirect to the new URL
    }

    /**
     * FAQ Accordions.
     */
    faq() {
        let section = $('article.faq'),
            toggle = $('.heading', section);

        toggle.on('click', function () {
            $(this).closest('article').toggleClass('open');
        });
    }

    /**
     * Accordions.
     */
    accordions() {
        let section = $('article.accordion');
        let toggle = $('.heading', section);
    
        toggle.on('click', function() {
            let article = $(this).closest('article');
            let label = $('.label', article)

            article.toggleClass('open');
            
            if (article.hasClass('open')) {
                label.text('Read Less');
            } else {
                label.text('Read More');
            }
        });
    }

    /**
     * Initialize the calendar with availability data.
     */
    initializeCalendar() {
        var calendarEl = $('#calendar');
        var availabilityDataString = calendarEl.attr('data-availability');

        if (!availabilityDataString) {
            return false
        }

        try {
            var availabilityData = JSON.parse(availabilityDataString);

            var datesToHighlight = availabilityData.reduce(function(acc, item) {
                acc[item.date] = item.status;
                return acc;
            }, {});

            var picker = new Pikaday({
                field: calendarEl[0],
                bound: false,
                onDraw: function() {
                    var days = document.querySelectorAll('.pika-single .pika-day');
                    var today = new Date().setHours(0, 0, 0, 0); // Normalize today's date to midnight
                    days.forEach(function(day) {
                        var year = day.getAttribute('data-pika-year');
                        var month = (parseInt(day.getAttribute('data-pika-month')) + 1).toString().padStart(2, '0'); // Adding 1 since months are zero-based
                        var dayNumber = day.textContent.padStart(2, '0');
                        var formattedDate = `${year}-${month}-${dayNumber}`;

                        var date = new Date(`${year}-${month}-${dayNumber}`).setHours(0, 0, 0, 0); // Normalize the date to midnight

                        // Check if the date is today or later
                        if (date >= today) {
                            var status = datesToHighlight[formattedDate];

                            // Wrap the day number with a span
                            var span = document.createElement('span');
                            span.textContent = day.textContent;
                            day.innerHTML = '';
                            day.appendChild(span);

                            if (status === 'Available') {
                                day.classList.add('available-date');
                            } else if (status === 'Not Available') {
                                day.classList.add('unavailable-date');
                            }
                        }
                    });
                }
            });

            // Attach the calendar to the custom container
            document.getElementById('calendar-container').appendChild(picker.el);

        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }
    
    /**
     * Initializes carousels using Flickity within sections of the main slider.
     *
     * This function searches for elements with the class `.main .slider` and initializes
     * Flickity carousels within each of them. It sets up the next/previous button controls,
     * updates their states based on the current slide index, and handles dot navigation.
     *
     * @returns {boolean} False if no sections are found; otherwise, void.
     */
    carousel() {
        let sections = gsap.utils.toArray('.slider');

        if (!sections.length) {
            return false;
        }

        sections.forEach(section => {
            let main = $('.main-carousel', section)[0],
                next = $(section).find('.next'),
                prev = $(section).find('.prev'),
                dots = $(section).find('.dots'),
                li = dots.find('li');

            var slider = new Flickity(main, {
                cellAlign: 'left',
                contain: true,
                pageDots: false,
                prevNextButtons: false,
                // adaptiveHeight: true,
            });

            // Event listener for slider select event
            slider.on('select', function() {
                let index = slider.selectedIndex;
                li.removeClass('active');
                li.eq(index).addClass('active');
            });

            // Event listener for dot clicks
            dots.on('click', 'li', function() {
                var index = $(this).index();
                slider.select(index);
            });

            /**
             * Event listener for the next button click.
             * Moves to the next slide.
             */
            next.on('click', function() {
                slider.next();
            });

            /**
             * Event listener for the previous button click.
             * Moves to the previous slide.
             */
            prev.on('click', function() {
                slider.previous();
            });
        });
    }

    /**
     * Initializes carousels using Flickity within sections of the main slider.
     *
     * This function searches for elements with the class `.main .slider` and initializes
     * Flickity carousels within each of them. It sets up the next/previous button controls,
     * updates their states based on the current slide index, and handles dot navigation.
     *
     * @returns {boolean} False if no sections are found; otherwise, void.
     */
    carouselAuto() {
        let sections = gsap.utils.toArray('.slider-auto');

        if (!sections.length) {
            return false;
        }

        sections.forEach(section => {
            let main = $('.main-carousel', section)[0],
                next = $(section).find('.next'),
                prev = $(section).find('.prev'),
                dots = $(section).find('.dots'),
                li = dots.find('li');

            var slider = new Flickity(main, {
                cellAlign: 'center',
                contain: true,
                pageDots: false,
                autoPlay: true,
                prevNextButtons: false,
                adaptiveHeight: true,
            });

            // Event listener for slider select event
            slider.on('select', function() {
                let index = slider.selectedIndex;
                li.removeClass('active');
                li.eq(index).addClass('active');
            });

            // Event listener for dot clicks
            dots.on('click', 'li', function() {
                var index = $(this).index();
                slider.select(index);
            });

            /**
             * Event listener for the next button click.
             * Moves to the next slide.
             */
            next.on('click', function() {
                slider.next();
            });

            /**
             * Event listener for the previous button click.
             * Moves to the previous slide.
             */
            prev.on('click', function() {
                slider.previous();
            });
        });
    }
}
